<template>
  <div>
    <div
      class="card mb-3"
      style="width: 100%"
      v-loading="loading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="card-header bg-light">
        <h3 class="title fw-normal mb-0 p-2"> <img src="https://customer.vitelglobal.com/assets/img/logo.svg" style="width:10%" />&nbsp;&nbsp; Settings</h3>
      </div>
      <!-- No Data Section -->
      <el-row :gutter="12" v-if="!showTextArea && !vitelGlobalAccount">
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
          class="table-container mt-20 ml-3 mb-10"
        >
          <div class="no-data">
            <img src="@/assets/img/nodata2.svg" alt="No Data" />
            <p>
              There are currently no configurations available. Click 'Add' to create a configuration.
            </p>
            <div>
              <el-button type="primary" @click="createNewChatBot"
                >Add Configuration</el-button
              >
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="12" v-if="!showTextArea && vitelGlobalAccount">
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
          class="mt-20 mb-10"
        >          
          <div style="padding:0 0.55rem;">
            <table id="customers">
              <tr>
                <th>Name</th>
                <td>{{vitelGlobalAccount.name}}</td>
              </tr>
              <tr>
                <th>User Name</th>
                <td>{{vitelGlobalAccount.username}}</td>
              </tr>
              <tr>
                <th>Extension</th>
                <td>{{vitelGlobalAccount.extension}}</td>
              </tr>
              <tr>
                <th>From Number</th>
                <td>                  
                  <a href="#" @click.prevent="showPhoneModal(`+${vitelGlobalAccount.countryCode}${vitelGlobalAccount.nationalPhoneNumber}`)">+{{vitelGlobalAccount.countryCode}} {{vitelGlobalAccount.formattedNationalNumber}}</a>
                  
                </td>
              </tr>
            </table>
          </div>
        </el-col>
      </el-row>

      <!-- Text Area Section with Buttons (Conditional Rendering) -->
      <el-row :gutter="12" v-if="showTextArea">
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
          class="mb-10"
          
        >
        <!-- class="form-container" -->
        <form @submit.prevent="onSubmit" >
          <div class="field">
            <label for="name">Name:</label>
            <input type="text" id="name" v-model="form.name" v-validate="'required'" name="name" />
            <span class="error">{{ errors.first('name') }}</span>
          </div>
    
          <div class="field">
            <label for="username">Username:</label>
            <input type="text" v-model="form.username" v-validate="'required|min:4'" name="username" id="username" />
            <span class="error">{{ errors.first('username') }}</span>
          </div>
    
          <div class="field">
            <label for="password">Password:</label>
            <input type="password" v-model="form.password" v-validate="'required|min:6'" name="password" id="password" />
            <span class="error">{{ errors.first('password') }}</span>
          </div>
    
          <div class="field">
            <label for="extension">Extension Number:</label>
            <input type="text" v-model="form.extension" v-validate="'required|numeric'" name="extension" id="extension" />
            <span class="error">{{ errors.first('extension') }}</span>
          </div>
    
          <div class="field">
            <label for="destination">Destination Numbers:</label>
            <input type="text" v-model="form.destination"  v-validate="'required|numeric|min:10|max:15'"  name="destination"  id="destination" />

            <span class="error">{{ errors.first('destination') }}</span>
          </div>
          <div class="field">
          <button type="submit">Submit</button>
          </div>
        </form>
        </el-col>
      </el-row>
      
      <PhoneCallModal ref="phoneModal"/>
    </div>
  </div>
</template>
<style scoped>    
    .form-container {
      background-color: #fff;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      width: 100%;
      min-width: 360px;
    }
    .field{
      padding:0 0.35rem;
      margin:0.035rem;
    }
    .error{
      color: red;
    }

    h2 {
      text-align: center;
      margin-bottom: 20px;
      color: #333;
    }

    label {
      display: block;
      margin-bottom: 8px;
      font-weight: bold;
      color: #555;
    }

    input {
      width: 100%;
      padding: 10px 0px;
      margin-bottom: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 16px;
    }

    input:focus {
      border-color: #007BFF;
      outline: none;
    }

    .error-message {
      color: #FF0000;
      font-size: 14px;
      margin-bottom: 10px;
    }

    button {
      width: 100%;
      padding: 10px;
      background-color: #007BFF;
      border: none;
      border-radius: 5px;
      color: white;
      font-size: 16px;
      cursor: pointer;
    }

    button:hover {
      background-color: #0056b3;
    }

    
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}
/*
background-color: #04AA6D;
color: white;
*/
#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;

}

</style>

<script>
import { postAPICall } from "@/helpers/httpHelper";
import { mapGetters } from "vuex";

import { validateAndFormatPhoneNumber } from "@/utils/utility";

export default {
  data() {
    return {
      loading: false,
      loadingText: "Loading ...",
      vitelGlobalAccount: {}, // Existing account
      showTextArea: false, // Manage text area visibility
      newConfiguration: "", // Holds the details for the new Chat Bot or selected bot
      editing: false, // Flag to track if we're editing an existing bot
      selectedBotId: null, // Holds the ID of the selected bot for editing
      form: {
          name: '',
          username: '',
          password: '',
          extension: '',
          destination: ''
        }
    };
  },
  async mounted() {
    this.getAccounts();
  },
  computed: {
    ...mapGetters("auth", [
      "getActiveWorkspace",
      "getAuthenticatedUser",
      "getWorkspaceList",
    ]),
  },
  methods: {
    showPhoneModal(phoneNumber) {
      this.$refs.phoneModal.showAppSelection(phoneNumber);
    },
    async onSubmit() {
          this.$validator.validateAll().then(async (result) => {
            if (result) {
              console.log("form values",result,this.form);
              const configuration = this.$CryptoJS.AES.encrypt(JSON.stringify(this.form),"VitelGlobal").toString();
              console.log("configuration",configuration)
              this.loading = true;
          this.loadingText = "Please wait.....";
          try {
            let data={
              appName:"VITEL_GLOBAL", clientSecret:configuration, isActive:true
            }
           let response= await postAPICall("POST","/user_integrations/createIntegration", data)
            if (response && response.success) {
              this.$message.success("Vital Global Configuration added successfully!");

              // Reload the current page
              // window.location.reload();
            } else {
              this.$message.error(
                "Failed to create Chat Bot: " + response.message
              );
            }
          } catch (error) {
            console.error(error);
            this.$message.error(
              "An error occurred while creating the Chat Bot."
            );
          } finally {
            this.loading = false;
          }

            } else {
              // alert('Please correct the errors.');
              console.log('Please correct the errors.');
            }
          });
    },
    async getAccounts() {
      this.loading = true;
      this.loadingText = "Loading configurations ...";
      try {
        let response = await postAPICall("GET", "/user_integrations/list/VITEL_GLOBAL");
        if (response && response.success) {
          console.log("Response",response);
          const decrypted = this.$CryptoJS.AES.decrypt(response.data.clientSecret, 'VitelGlobal')
          const info = decrypted.toString(this.$CryptoJS.enc.Utf8);
          this.vitelGlobalAccount = JSON.parse(info);

          const format = validateAndFormatPhoneNumber(this.vitelGlobalAccount.destination);

          if(format.isValid)
        {
          this.vitelGlobalAccount={...this.vitelGlobalAccount,...format};
        }
        else{
          // this.$message.error(errorMessage);
          console.log(format.errorMessage)
        }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.no-data {
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

/* Scrollbar for textarea */
.scrollable-textarea .el-textarea__inner {
  max-height: 200px;
  overflow-y: auto;
}
</style>
